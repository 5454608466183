import Box from "@mui/material/Box";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { List, ListItem } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useEffect } from "react";

const CList = styled(List)`
  width: 95%;
  background-color: white;
  margin: 32px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;

const CListItem = styled(ListItem)`
  padding: 16px 0 16px 16px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  color: #54667a;
`;

export function DashboardPage() {
  const { dashboards, user } = useAuth();
  const navigate = useNavigate();
  const { categoryId: selectedCategoryId, dashboardId: selectedDashboardId } =
    useParams();

  useEffect(() => {
    if (selectedDashboardId) {
      const d = dashboards?.filter(
        (i) =>
          i.Dashboards.filter((j) => j.Id === selectedDashboardId).length > 0
      );
      if (d && d?.length === 0) {
        navigate("/dashboard");
      }
    }
  }, [selectedDashboardId]);

  if (!selectedCategoryId) {
    return (
      <CList>
        {dashboards?.map((i) => (
          <NavLink key={i.Id} to={`/dashboard/${i.Id}`}>
            <CListItem secondaryAction={<ChevronRight />}>
              {i.CategoryName}
            </CListItem>
          </NavLink>
        ))}
      </CList>
    );
  }

  if (!selectedDashboardId) {
    return (
      <CList>
        {dashboards
          ?.filter((i) => i.Id === selectedCategoryId)?.[0]
          ?.Dashboards.map((i) => (
            <NavLink
              key={i.Id}
              to={`/dashboard/${selectedCategoryId}/sub-category/${i.Id}`}
            >
              <CListItem secondaryAction={<ChevronRight />}>{i.Name}</CListItem>
            </NavLink>
          ))}
      </CList>
    );
  }
  const dashboard = dashboards
    ?.filter((i) => i.Id === selectedCategoryId)?.[0]
    ?.Dashboards.filter((i) => i.Id === selectedDashboardId)?.[0];
  return (
    <Box sx={{ flexGrow: 1 }}>
      {selectedDashboardId ? (
        <iframe
          title={dashboard?.Id}
          src={encodeURI(
            `https://bi.scalar.ws/bi/site/automotive/dashboards/${dashboard?.Id}/${dashboard?.CategoryName}/${dashboard?.Name}?isembed=true&embed_user_token=${user?.access_token}&dashboard_comments=true&widget_comments=true&export=true`
          )}
          id="dashboard-frame"
          width="99%"
          height={window.screen.height - 204}
          allowFullScreen
          frameBorder="0"
        ></iframe>
      ) : null}
    </Box>
  );
}
