export default {
  RAVPRO: "dashboard",
  PREMIAÇÕES: "cup",
  PEÇAS: "package",
 "PEÇAS - INDIVIDUAL": "hummer",
  SYONET: "shortcode",
  VEICULOS: "car",
  "VEICULOS - INDIVIDUAL": "key",
  "PÓS-VENDAS": "archive",
  "PÓS-VENDAS - INDIVIDUAL": "receipt",
  VENDAS: "shopping-cart-full",
  "VENDAS - INDIVIDUAL": "shopping-cart" 

} as Record<string, string>;
